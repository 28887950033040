import React from 'react'

import { Tooltip } from '@mui/material'

interface TooltipProps {
  title?: string
  children: React.ReactElement
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

export const TooltipWrapper: React.FC<TooltipProps> = ({
  title,
  children,
  placement = 'left'
}) => {
  return (
    <Tooltip
      {...{
        title,
        placement
      }}
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            bgcolor: '#320033',
            padding: '12px',
            color: 'white',
            fontSize: '12px',
            fontFamily: 'Montserrat'
          }
        }
      }}
    >
      {children}
    </Tooltip>
  )
}
