import axios, { AxiosInstance } from 'axios'
import i18n from 'i18next'
import 'i18n'

import { getInitialState } from 'store/persistState'

type APIHeader = {
  'Content-Type': string
  token?: string
  'Recrud-Tenant': string
  'Workspace-Tenant'?: string
}

type APIParams = {
  lang: string
}

async function createAPI(): Promise<{
  api: AxiosInstance
  apiNoInterceptors: AxiosInstance
  apiWithoutAccessToken: AxiosInstance
}> {
  let headers: APIHeader = {
    'Content-Type': '',
    'Recrud-Tenant': ''
  }
  const params: APIParams = {
    lang: ''
  }

  const getAuthData = async () => {
    try {
      const auth = await getInitialState('auth')
      const workspace = await localStorage.getItem('Workspace-Tenant')
      const company = (await localStorage.getItem('Company-Tenant')) as string
      params.lang = i18n.language?.split('-')[0]
      if (!auth?.user?.company?.uuid) {
        return {
          headers: {
            'Content-Type': 'application/json',
            'Recrud-Tenant': 'reload'
          },
          baseURL: process.env.REACT_APP_API,
          params,
          timeout: 60 * 1000
        }
      }
      headers = {
        'Content-Type': 'application/json',
        'Recrud-Tenant': company,
        token: auth.token
      }

      if (workspace) {
        headers['Workspace-Tenant'] = workspace
      }

      return {
        baseURL: process.env.REACT_APP_API,
        headers,
        params,
        timeout: 60 * 1000
      }
    } catch (error) {
      console.error('Error while getting auth data:', error)
    }
  }

  const config = await getAuthData()

  const api = axios.create(config)
  const apiNoInterceptors = axios.create(config)

  delete headers.token
  const apiWithoutAccessToken = axios.create(config)

  return { api, apiNoInterceptors, apiWithoutAccessToken }
}

const apiInstance = await createAPI()

export const changeApiParams = (lang: string): void => {
  const language = lang?.split('-')[0]
  const params = { lang: language }
  apiInstance.api.defaults.params = params
  apiInstance.apiNoInterceptors.defaults.params = params
  apiInstance.apiWithoutAccessToken.defaults.params = params
}

export default apiInstance.api
export const { apiNoInterceptors, apiWithoutAccessToken } = apiInstance
