import React from 'react'

export const BrazilFlag: React.FC = () => (
  <svg
    width='30'
    height='31'
    viewBox='0 0 30 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_4269_16835)'>
      <path
        d='M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 7.21573 23.2843 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5Z'
        fill='#6DA544'
      />
      <path
        d='M15.0007 6.37109L27.392 15.5015L15.0007 24.632L2.60938 15.5015L15.0007 6.37109Z'
        fill='#FFDA44'
      />
      <path
        d='M15.0006 20.716C17.882 20.716 20.2179 18.3801 20.2179 15.4986C20.2179 12.6171 17.882 10.2812 15.0006 10.2812C12.1191 10.2812 9.7832 12.6171 9.7832 15.4986C9.7832 18.3801 12.1191 20.716 15.0006 20.716Z'
        fill='#F0F0F0'
      />
      <path
        d='M12.3922 15.1719C11.4849 15.1719 10.6093 15.3099 9.78516 15.5659C9.82166 18.4159 12.1422 20.7154 15.0009 20.7154C16.7685 20.7154 18.3297 19.8355 19.2735 18.4907C17.6588 16.4694 15.1744 15.1719 12.3922 15.1719Z'
        fill='#0052B4'
      />
      <path
        d='M20.1221 16.4982C20.185 16.1746 20.2189 15.8406 20.2189 15.4986C20.2189 12.6171 17.883 10.2812 15.0015 10.2812C12.8515 10.2812 11.0058 11.5821 10.207 13.4394C10.9131 13.2931 11.6441 13.216 12.3929 13.216C15.4232 13.216 18.1645 14.4756 20.1221 16.4982Z'
        fill='#0052B4'
      />
    </g>
    <defs>
      <clipPath id='clip0_4269_16835'>
        <rect
          width='30'
          height='30'
          fill='white'
          transform='translate(0 0.5)'
        />
      </clipPath>
    </defs>
  </svg>
)
