import { AxiosResponse } from 'axios'

import { Answers } from 'components/CustomQuestions/CustomQuestions'
import { DashboardFiltersPayload } from 'containers/Dashboard/DashboardContainer'
import { APIResponse, handleResponse } from 'utils/apiResponse.util'
import { timestampToDate } from 'utils/date'
import { treatRemoteJobs } from 'utils/remoteJobe.util'
import { snakeKeyToCamelCase } from 'utils/snakeToCamelCase'

import api from './api'

// export const get = async ({
//   uuid
// }: {
//   uuid: string
// }): Promise<[APIError, Job | null]> => {
//   try {
//     const response = await api.get<{
//       data: JobSchema
//       profile: Profile[]
//       message?: string
//     }>(`/v1/jobs/${uuid}`)

//     if (response.status === 200) {
//       return [
//         null,
//         snakeKeyToCamelCase(response.data.data),
//         response.data.profile
//       ]
//     }

//     return [response.data?.message as string, null]
//   } catch (error) {
//     return [error.message, null]
//   }
// }

/**
 * Pega a lista de jobs da API.
 *
 * @param options - Opções para listagem de jobs.
 * @param options.page - Número de páginas. Padrão é 1.
 * @param options.perPage - Número de jobs por página. Padrão é 10.
 * @param options.filters - Filtros adicionais para lista de jobs.
 * @param options.filters.active - Filtra jobs ativos. Tipo string ou boolean.
 * @param options.filters.late - Filtra jobs atrasados. Tipo string ou boolean.
 * @param options.filters.filter - Filtra jobs por nome. Tipo string.
 * @param options.filters.all - Lista todos os jobs. Tipo boolean.
 * @returns Retorna uma Promise com { data: Job[]; total: number } ou mensagem de erro.
 */
export const list = async ({
  page = 1,
  perPage = 10,
  filters,
  workspaceId
}: ListJobsOptions): Promise<ListJobsResponse> => {
  try {
    const workspaceOnHeader = workspaceId
      ? { headers: { 'Workspace-Tenant': workspaceId } }
      : {}
    const { data: responseData }: AxiosResponse<GetJobsResponse> =
      await api.get(`/v1/jobs?page=${page}&per_page=${perPage}`, {
        params: { ...filters },
        ...workspaceOnHeader
      })
    const treatedJobs = treatRemoteJobs(
      responseData.data.map(snakeKeyToCamelCase)
    )

    return [
      null,
      {
        data: treatedJobs,
        total: responseData.total
      }
    ]
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null]
  }
}

export const job_group_search = async (
  search: string
): Promise<[APIError, JobGroupSchema[] | null]> => {
  try {
    const response = await api.get<{
      data: JobSchema[]
      message?: string
    }>(`/v1/jobs/job-groups`, {
      params: { job_name: search }
    })

    if (response.status === 200) {
      return [null, response.data.data.map(snakeKeyToCamelCase)]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

/**
 * Cria um novo job.
 *
 * @param payload - O payload contendo os dados para o novo job.
 * @returns Uma promise que resolve para uma tupla contendo uma mensagem de erro ou `null`, e os dados do job criado ou `null`.
 * returns format [error, JobSchema]
 */
export const create = async (
  payload: CreateJobPayload
): Promise<APIResponse<JobSchema>> => {
  try {
    const { status, data }: AxiosResponse<any> = await api.post(
      '/v1/jobs/',
      payload
    )

    return handleResponse<JobSchema>(status, data)
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null]
  }
}

export const createQuestions = async (payload: {
  uuid: string
  data: {
    description: string
    eliminate: boolean
    type: 'multiple_choice' | 'selectable_multiple_choice' | 'open_ended'
    answers?: Array<Answers>
  }
}): Promise<[APIError, JobSchema | null]> => {
  try {
    const response = await api.post(
      `/v1/jobs/${payload.uuid}/questions`,
      payload.data
    )

    if (response.status === 201) {
      const data: JobSchema = response.data.data
      return [
        null,
        {
          uuid: data.uuid,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          name: data.name,
          city: data.city,
          deadline: data.deadline,
          active: data.active,
          company: data.company,
          hiring_workflow: data.hiring_workflow,
          budget: data.budget,
          job_group_uuid: data.job_group_uuid,
          job_group: data.job_group,
          vacancyAllocation: data.vacancyAllocation,
          quantity: data.quantity,
          description: data.description,
          tags: data.tags,
          recruiters_team: data.recruiters_team,
          managers_team: data.managers_team
        }
      ]
    }

    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 409 ||
      response.status === 500
    ) {
      const data: ErrorSchema = response.data
      return [data.message, null]
    }

    if (response.status === 422) {
      const data: ErrorEntitySchema = response.data
      return [
        {
          message: data.message,
          errors: data.errors
        },
        null
      ]
    }

    return ['unmapped', null]
  } catch (error) {
    return [error.message, null]
  }
}

export const updateCandidateAplication = async ({
  job_uuid,
  aplication_uuid,
  hiring_workflow_stage
}: {
  job_uuid: string
  aplication_uuid: string
  hiring_workflow_stage: string
}): Promise<[APIError, JobApplication | null]> => {
  try {
    const response = await api.patch(
      `/v1/jobs/${job_uuid}/applications/${aplication_uuid}`,
      { hiring_workflow_stage }
    )

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const updateCandidateApplicationWithProfile = async ({
  job_uuid,
  aplication_uuid,
  profile_uuid,
  hiring_workflow_stage
}: {
  job_uuid: string
  aplication_uuid: string
  profile_uuid: string
  hiring_workflow_stage: string
}): Promise<[APIError, JobApplication | null]> => {
  try {
    const response = await api.patch(
      `/v1/jobs/${job_uuid}/applications/${aplication_uuid}/profile/${profile_uuid}`,
      { hiring_workflow_stage }
    )

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const applyToJob = async (
  jobApplyPayload: JobApplyPayload
): Promise<[APIError, null | boolean]> => {
  try {
    const jobUUID = jobApplyPayload.jobUUID
    const payload = {
      candidate: jobApplyPayload.candidate,
      hiring_workflow_stage: jobApplyPayload.hiringWorkflowStage
    }
    const workspaceOnHeader = jobApplyPayload.workspaceId
      ? { headers: { 'Workspace-Tenant': jobApplyPayload.workspaceId } }
      : {}
    const response = await api.post(
      `/v1/jobs/${jobUUID}/applications`,
      payload,
      {
        ...workspaceOnHeader
      }
    )

    if (response.status === 200) {
      return [null, true]
    }
    if (response.status >= 400 && response.status <= 500) {
      throw new Error(response.data.message as string)
    }
    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

/**
 * Lê os detalhes de um trabalho com base no seu UUID.
 * @param uuid O UUID do trabalho a ser lido.
 * @returns Uma Promise que resolve em uma tupla contendo o erro (caso ocorra),
 *          os detalhes do trabalho lido, o perfil de resposta e as etapas do fluxo de trabalho.
 * returns format [error, Job, Profile[], Stage[]]
 */
export const read = async (
  uuid: string,
  showDataFromAnotherWorkspace?: boolean
): Promise<ReadJobResponse> => {
  try {
    const response: AxiosResponse<ReadJobResponseData> = await api.get(
      `/v1/jobs/${uuid}`,
      {
        ...(showDataFromAnotherWorkspace && {
          headers: { showDataFromAnotherWorkspace: true }
        })
      }
    )

    if (response.status === 204) {
      throw new Error('Not authorized, please check the workspace')
    }

    const jobInfo = response.data.data

    const treatedResponse: Job = {
      ...jobInfo,
      deadline: jobInfo.deadline ? timestampToDate(jobInfo.deadline) : '',
      ...(response.data.work_space &&
        response.data.work_space.length > 0 && {
          workspace: response.data.work_space[0]
        })
    }

    return [null, treatedResponse, response.data.profile, response.data.stages]
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null, null, null]
  }
}

export const listQuestions = async (
  uuid: string
): Promise<[APIError, Questions[] | null]> => {
  try {
    const response = await api.get<{
      data: Questions[]
      message?: string
    }>(`/v1/jobs/${uuid}/questions`)

    if (response.status === 200) {
      return [null, response.data.data]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}
interface ListApplicationsPayload {
  job_uuid: string
  page: number
  per_page: number
  order_by?: string
  hiring_workflow_stage?: string
  geo_location?: string
  min_age?: number
  max_age?: number
  sex?: string
  marital_status?: string
  salary_expectation_min?: number
  salary_expectation_max?: number
  city_relocation?: boolean
  educational_institution?: string
  educational_course?: string
  educational_level?: string
  professional_experience_company?: string
  professional_experience_role?: string
  professional_experience_years?: string
  language?: string
  language_fluency?: string
}

export const listApplications = async (
  payload: ListApplicationsPayload
): Promise<[APIError, JobApplication[] | null]> => {
  Object.keys(payload).map(key => {
    if (!payload[key] || payload[key] === '0' || payload[key].length < 1) {
      return delete payload[key]
    }
    return true
  })
  try {
    const response = await api.get<{
      data: JobApplicationSchema[]
      per_page: number
      page: number
      message?: string
    }>(`/v1/jobs/${payload.job_uuid}/applications`, {
      params: { ...payload }
    })

    if (response.status === 200) {
      return [null, response.data.data.map(snakeKeyToCamelCase)]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const updateQuestions = async (
  jobsUUID: string,
  uuid: string,
  payload: any
): Promise<[APIError, Questions[] | null]> => {
  try {
    const response = await api.patch<{
      data: Questions[]
      message?: string
    }>(`/v1/jobs/${jobsUUID}/questions/${uuid}`, payload)

    if (response.status === 200) {
      return [null, response.data.data]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

/**
 * Atualiza um trabalho com base no payload fornecido.
 *
 * @param payload - O payload contendo os dados para atualização do trabalho.
 * @param uuid - O UUID do trabalho a ser atualizado.
 * @returns Uma Promise que resolve em uma tupla contendo o erro (se houver) e os dados atualizados do trabalho.
 * returns format [error, JobSchema]
 */
export const update = async (
  payload: UpdateJobPayload,
  uuid: string
): Promise<APIResponse<JobSchema>> => {
  try {
    const { status, data }: AxiosResponse<any> = await api.patch(
      `/v1/jobs/${uuid}`,
      payload
    )

    return handleResponse<JobSchema>(status, data)
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null]
  }
}

export const deleteQuestions = async (
  job_uuid: string,
  uuid: string
): Promise<[APIError, boolean | null]> => {
  const response = await api.delete(`/v1/jobs/${job_uuid}/questions/${uuid}`)

  if (response.status === 204) {
    return [null, true]
  }

  if (
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const notes = async ({
  jobUUID,
  applicationUUID,
  page = 1,
  perPage = 10
}: {
  jobUUID: string
  applicationUUID: string
  page?: number
  perPage?: number
}): Promise<[APIError, JobApplicationNote[] | null]> => {
  try {
    const response = await api.get<{
      data: JobApplicationLogSchema[]
      per_page: number
      page: number
      message?: string
    }>(`/v1/jobs/${jobUUID}/applications/${applicationUUID}/notes`, {
      params: { page, per_page: perPage }
    })

    if (response.status === 200) {
      const notes = response.data.data.map(note => {
        return snakeKeyToCamelCase(note)
      })

      return [null, notes]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const addNote = async ({
  jobUUID,
  applicationUUID,
  note
}: {
  jobUUID: string
  applicationUUID: string
  note: string
}): Promise<[APIError, JobApplicationNote | null]> => {
  try {
    const response = await api.post(
      `/v1/jobs/${jobUUID}/applications/${applicationUUID}/notes`,
      { content: note }
    )

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const countApplications = async ({
  uuid,
  filter
}: {
  uuid: string
  filter: FilterCandidate
}): Promise<[APIError, { [key: string]: number } | null]> => {
  Object.keys(filter).map(key => {
    if (!filter[key] || filter[key].length < 1 || filter[key] === '0') {
      return delete filter[key]
    }
    return true
  })

  try {
    const response = await api.get(`/v1/jobs/${uuid}/applications/info`, {
      params: { ...filter }
    })

    if (response.status === 200) {
      let result = {}
      response.data.data.map(element => {
        const key = `${element.hiring_workflow_stage.uuid}`
        return (result = {
          ...result,
          ...{ [key]: element.number_of_applications }
        })
      })

      return [null, result]
    }

    if (
      response.status === 401 ||
      response.status === 404 ||
      response.status === 500
    ) {
      const data: ErrorSchema = response.data
      return [data.message, null]
    }

    return ['unmapped', null]
  } catch (error) {
    return [error.message, null]
  }
}

export const logs = async ({
  jobUUID,
  applicationUUID,
  page = 1,
  perPage = 10
}: {
  jobUUID: string
  applicationUUID: string
  page?: number
  perPage?: number
}): Promise<[APIError, LogsSchema[] | null]> => {
  try {
    const response = await api.get<{
      data: JobApplicationNoteSchema[]
      per_page: number
      page: number
      message?: string
    }>(`/v1/jobs/${jobUUID}/applications/${applicationUUID}/logs`, {
      params: { page, per_page: perPage }
    })

    if (response.status === 200) {
      const logs: LogsSchema[] = response.data.data.map(log => {
        return snakeKeyToCamelCase(log)
      })

      return [null, logs]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const reviews = async ({
  jobUUID,
  applicationUUID
}: {
  jobUUID: string
  applicationUUID: string
}): Promise<[APIError, JobApplicationReview | null]> => {
  try {
    const response = await api.get<{
      data: JobApplicationReviewSchema
      message?: string
    }>(`/v1/jobs/${jobUUID}/applications/${applicationUUID}/reviews`)

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }

    if (response.status === 404) {
      return [
        null,
        {
          author: { uuid: '' },
          jobApplication: { uuid: applicationUUID },
          review: 0,
          createdAt: 0,
          updatedAt: 0,
          uuid: ''
        }
      ]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const addReview = async ({
  jobUUID,
  applicationUUID,
  review
}: {
  jobUUID: string
  applicationUUID: string
  review: number
}): Promise<[APIError, JobApplicationReview | null]> => {
  try {
    const response = await api.put<{
      data: JobApplicationReviewSchema
      message?: string
    }>(`/v1/jobs/${jobUUID}/applications/${applicationUUID}/reviews`, {
      review
    })

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const answers = async ({
  jobUUID,
  applicationUUID
}: {
  jobUUID: string
  applicationUUID: string
}): Promise<[APIError, JobApplicationAnswersSchema[] | null]> => {
  try {
    const response = await api.get<{
      data: JobApplicationAnswersSchema[]
      message?: string
    }>(`/v1/jobs/${jobUUID}/applications/${applicationUUID}/answers`)

    if (response.status === 200) {
      const answers = response.data.data.map(log => {
        return snakeKeyToCamelCase(log)
      })

      return [null, answers]
    }

    return [response.data.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const jobApplicationsLastThirtyDays = async (
  params: DashboardFiltersPayload
) => {
  const response = await api.get<{
    data
    message?: string
  }>(`/v1/dashboard/job-applications/count-last-thirty-days`, {
    params
  })

  if (response?.status === 200) {
    return [response?.status, response?.data?.data]
  } else return [response?.status, undefined]
}

export const jobApplicationsLastSixMonths = async (
  params: DashboardFiltersPayload
) => {
  const response = await api.get<{
    data
    message?: string
  }>(`/v1/dashboard/jobs/avg-closing-time-last-six-months`, {
    params
  })

  if (response?.status === 200) {
    return [response?.status, response?.data?.data]
  } else return [response?.status, undefined]
}

export const jobClosingTime = async (params: DashboardFiltersPayload) => {
  const response = await api.get<{
    data
    message?: string
  }>(`/v1/dashboard/jobs/avg-closing-time-by-recruiter`, { params })

  if (response?.status === 200) {
    return [response?.status, response?.data?.data]
  } else return [response?.status, undefined]
}

export const jobOpen = async (params: DashboardFiltersPayload) => {
  const response = await api.get<{
    data: any
    message?: string
  }>(`/v1/dashboard/jobs/openness`, { params })
  if (response?.status === 200) {
    return [response?.status, response?.data?.data]
  } else return [response?.status, undefined]
}

export const jobClosedByRecruiter = async (params: DashboardFiltersPayload) => {
  const response = await api.get<{
    data
    message?: string
  }>(`/v1/dashboard/jobs/closed-by-recruiter`, { params })
  if (response?.status === 200) {
    return [response?.status, response?.data?.data]
  } else return [response?.status, undefined]
}
