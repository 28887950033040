import { dark } from '@mui/material/styles/createPalette'

export enum Theme {
  RECRUD = 'recrud',
  LUGARH = 'lugarh',
  BNE = 'bne',
  CUSTOM = 'custom'
}

export const defaultColors = {
  [Theme.RECRUD]: {},
  [Theme.LUGARH]: {
    primary: '#950099',
    primary_dark: '#5a005d',
    primary_light: '#cb00d2',
    primary_surface: '#F4EDF5',
    secondary: '#0288D1',
    secondary_dark: '#01579B',
    secondary_light: '#03A9F4',
    enable_border: '#00B1B73B',
    active_border: '#A7D1D2',
    tertiary: '#6AC425',
    tertiary_dark: '#5EAE21',
    tertiary_light: '#7FCD43',
    success_surface: '#E8F8D8',
    pending_surface: '#CA9F02',
    alert_light: '#f8d85a',
    alert_surface: '#FCF5D1',
    error_default: '#D32F2F',
    surface_primary: '#b9a0b910',
    surface_information: '#EAF1FF66',
    light: '#ffffff',
    text_primary: '#141314',
    lightest_grey: '#F2F2F2',
    lightest_clear_grey: '#f8f8f8',
    lighter_grey: '#E6E6E6',
    light_grey: '#CCCCCC',
    medium_light_grey: '#B3B3B3',
    medium_grey: '#999999',
    medium_dark_grey: '#8C8C8C',
    dark_grey: '#666666',
    darker_grey: '#4D4D4D',
    background_light: '#ffffff',
    darkest_grey: '#333333',
    almost_black: '#1A1A1A',
    base_shadow: '#00000040',
    primary_shadow: '#95009940',
    secondary_shadow: '#0288D140',
    tertiary_shadow: '#6AC42540'
  },
  [Theme.BNE]: {
    primary: '#00594E',
    primary_darkest: '#00796b',
    green_dark: '#003024',
    base_shadow: '#00000040',
    pending_surface: '#CA9F02',
    alert_surface: '#ffca28',
    success_surface: '#E8F8D8',
    lighter_grey: '#E6E6E6',
    primary_dark: '#00000061',
    primary_light: '#cb00d2',
    secondary: '#00A18A',
    light: '#ffffff',
    secondary_dark: '#01579B',
    secondary_light: '#0A655A',
    lightest_clear_grey: '#f8f8f8',
    lightest_grey: '#f5f5f5',
    tertiary: '#00594E',
    tertiary_dark: '#055148',
    tertiary_light: '#7FCD43',
    background_light: '#ffffff',
    text_primary: '#141314',
    mid_grey: '#00000099',
    dark_grey: '#00000061'
  },
  [Theme.CUSTOM]: {}
}

export const themeClasses: ThemeClasses = {
  [Theme.RECRUD]: 'theme-recrud',
  [Theme.LUGARH]: 'theme-lugarh',
  [Theme.BNE]: 'theme-lugarh theme-bne',
  [Theme.CUSTOM]: 'theme-lugarh theme-custom'
}
