import React from 'react'

export const UnitedStatesFlag: React.FC = () => (
  <svg
    width='30'
    height='31'
    viewBox='0 0 30 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_4269_16836)'>
      <path
        d='M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 7.21573 23.2843 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5Z'
        fill='#F0F0F0'
      />
      <path
        d='M14.3496 15.499H30.0018C30.0018 14.1451 29.8213 12.8336 29.485 11.5859H14.3496V15.499Z'
        fill='#D80027'
      />
      <path
        d='M14.3496 7.67478H27.8002C26.8819 6.17643 25.7079 4.85203 24.339 3.76172H14.3496V7.67478Z'
        fill='#D80027'
      />
      <path
        d='M15.0013 30.4991C18.5315 30.4991 21.7763 29.279 24.3386 27.2383H5.66406C8.22637 29.279 11.4711 30.4991 15.0013 30.4991Z'
        fill='#D80027'
      />
      <path
        d='M2.20244 23.3271H27.7992C28.5364 22.1243 29.1081 20.8094 29.4841 19.4141H0.517578C0.893574 20.8094 1.46527 22.1243 2.20244 23.3271Z'
        fill='#D80027'
      />
      <path
        d='M6.94828 2.84246H8.31521L7.04373 3.76619L7.52941 5.26086L6.25799 4.33713L4.98656 5.26086L5.40609 3.96963C4.2866 4.90215 3.30539 5.99469 2.4968 7.21203H2.93479L2.12543 7.80002C1.99934 8.01037 1.8784 8.22406 1.7625 8.44092L2.14898 9.63043L1.42793 9.10654C1.24869 9.48629 1.08475 9.87459 0.937383 10.271L1.36318 11.5816H2.93479L1.6633 12.5053L2.14898 14L0.877559 13.0763L0.115957 13.6296C0.0397266 14.2424 0 14.8665 0 15.5H15C15 7.21578 15 6.23914 15 0.5C12.0368 0.5 9.27451 1.35957 6.94828 2.84246ZM7.52941 14L6.25799 13.0763L4.98656 14L5.47225 12.5053L4.20076 11.5816H5.77236L6.25799 10.0869L6.74361 11.5816H8.31521L7.04373 12.5053L7.52941 14ZM7.04373 8.13576L7.52941 9.63043L6.25799 8.7067L4.98656 9.63043L5.47225 8.13576L4.20076 7.21203H5.77236L6.25799 5.71736L6.74361 7.21203H8.31521L7.04373 8.13576ZM12.9098 14L11.6384 13.0763L10.367 14L10.8527 12.5053L9.58119 11.5816H11.1528L11.6384 10.0869L12.124 11.5816H13.6956L12.4242 12.5053L12.9098 14ZM12.4242 8.13576L12.9098 9.63043L11.6384 8.7067L10.367 9.63043L10.8527 8.13576L9.58119 7.21203H11.1528L11.6384 5.71736L12.124 7.21203H13.6956L12.4242 8.13576ZM12.4242 3.76619L12.9098 5.26086L11.6384 4.33713L10.367 5.26086L10.8527 3.76619L9.58119 2.84246H11.1528L11.6384 1.34779L12.124 2.84246H13.6956L12.4242 3.76619Z'
        fill='#0052B4'
      />
    </g>
    <defs>
      <clipPath id='clip0_4269_16836'>
        <rect
          width='30'
          height='30'
          fill='white'
          transform='translate(0 0.5)'
        />
      </clipPath>
    </defs>
  </svg>
)
