import React from 'react'

import { Button, CircularProgress } from '@mui/material'
import './ThemeButton.style.scss'

export enum ThemeButtonTemplate {
  PRIMARY = 'primary',
  PRIMARY_BLUE = 'primary-blue',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  TEXT_ONLY = 'text-only',
  QUICK_ACTION = 'quick-action'
}

interface ThemeButtonProps {
  template: ThemeButtonTemplate
  title?: string
  onClick?: () => void
  type?: 'button' | 'submit'
  startIcon?: React.ReactNode
  centerIcon?: React.ReactNode
  endIcon?: React.ReactNode
  customClass?: string
  disabled?: boolean
  isLoading?: boolean
}

export const ThemeButton: React.FC<ThemeButtonProps> = ({
  template,
  title,
  startIcon,
  endIcon,
  centerIcon,
  onClick,
  type = 'button',
  customClass = '',
  disabled = false,
  isLoading = false
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      event.preventDefault()
      onClick()
    }
  }

  const buttonVariant = (() => {
    switch (template) {
      case ThemeButtonTemplate.OUTLINED:
        return 'outlined'
      case ThemeButtonTemplate.TEXT_ONLY:
        return 'text'
      default:
        return 'contained'
    }
  })()

  return (
    <Button
      type={type}
      variant={buttonVariant}
      size='large'
      onClick={e => handleOnClick(e)}
      className={`theme-button theme-button--${template} ${customClass} default-font txt-m txt-semi-bold`}
      disabled={disabled}
      {...(startIcon && { startIcon })}
      endIcon={endIcon}
    >
      {isLoading ? (
        <CircularProgress className='theme-button__loading' />
      ) : (
        <>
          {centerIcon && (
            <span className='theme-button__center-icon'>{centerIcon}</span>
          )}
          {title}
        </>
      )}
    </Button>
  )
}
