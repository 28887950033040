import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { Theme, defaultColors, themeClasses } from './ThemeContext.config'
import { changeColors } from './ThemeContext.utils'

const defaultTheme: ThemeContextType = {
  theme: Theme.RECRUD,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {}
}

const ThemeContext = createContext<ThemeContextType>(defaultTheme)

interface ThemeControllerProps {
  children: React.ReactNode
}

export const ThemeController: React.FC<ThemeControllerProps> = ({
  children
}) => {
  const [theme, setTheme] = useState<Theme>(Theme.RECRUD)

  const handleSetTheme = useCallback(
    (newTheme: Theme) => setTheme(newTheme),
    []
  )

  useEffect(() => {
    if (theme === Theme.LUGARH) {
      changeColors(defaultColors[Theme.LUGARH])
    }
    if (theme === Theme.BNE) {
      changeColors(defaultColors[Theme.BNE])
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>
      <div className={themeClasses[theme]}>{children}</div>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context)
    throw new Error('useTheme deve ser usado dentro de um ThemeProvider')
  return context
}
