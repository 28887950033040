/* istanbul ignore file */

import { ActionMap } from 'store/types'

export type State = {
  accessToken: string | null
  token: string | null
  refreshToken: string | null
  expiresIn: number | null
  refreshExpiresIn: number | null
  loading: boolean
  error: string | null
  loginError: string | null
  registerError: string | null
  keepConnected: boolean
  user: User | null
}

export enum Types {
  CLEAR_ERRORS = 'auth/CLEAR_ERRORS',
  ENABLE_LOADING = 'auth/ENABLE_LOADING',
  DISABLE_LOADING = 'auth/DISABLE_LOADING',
  REGISTER = 'auth/REGISTER',
  TOKEN = 'auth/TOKEN',
  SET_TOKEN = 'auth/SET_TOKEN',
  SET_ERROR = 'auth/ERROR',
  SET_LOGIN_ERROR = 'auth/SET_LOGIN_ERROR',
  SET_REGISTER_ERROR = 'auth/SET_REGISTER_ERROR',
  SET_USER = 'auth/SET_USER',
  SET_CURRENT_WORKSPACE = 'auth/SET_CURRENT_WORKSPACE',
  SET_COMPANY_ID = 'auth/SET_COMPANY_ID',
  SET_COMPANY = 'auth/SET_COMPANY',
  SET_KEEP_CONNECTED = 'auth/SET_KEEP_CONNECTED',
  LOGOFF = 'auth/LOGOFF',
  SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
}

export type Payload = {
  [Types.TOKEN]: TokenPayload
  [Types.REGISTER]: RegisterPayload
  [Types.CLEAR_ERRORS]: null
  [Types.ENABLE_LOADING]: null
  [Types.DISABLE_LOADING]: null
  [Types.SET_TOKEN]: Token
  [Types.SET_USER]: User
  [Types.SET_CURRENT_WORKSPACE]: Omit<Workspace, 'slug'>
  [Types.SET_CURRENT_COMPANY]: Omit<Companie, 'slug'>
  [Types.SET_COMPANY_ID]: string
  [Types.SET_COMPANY]: CompanyUser
  [Types.SET_KEEP_CONNECTED]: boolean
  [Types.SET_LOGIN_ERROR]: { error: string }
  [Types.SET_REGISTER_ERROR]: { error: string }
  [Types.LOGOFF]: null
  [Types.SET_ERROR]: string
}

export type Selector = {
  isLoading(): boolean
  hasLoginError(): string | null
  hasRegisterError(): string | null
  logged(): boolean
  user(): User | null
  fullName(): string
  clearUser(): void
  keepConnected(): boolean
  refreshToken(): string | null
  expiresIn(): number | null
  companyId(): string
  companySlug(): string
  hasCompany(): boolean
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
