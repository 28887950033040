import React, { RefObject, useEffect, useState } from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CardMenu } from 'components/CardMenu/CardMenu'
import {
  ChangeEnvironmentModal,
  ModalType
} from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'

import './HeaderSettings.scss'

type Props = {
  initialState: boolean
  wrapperRef: RefObject<HTMLElement>
  onChange: (value: boolean) => void
  role: string
  email?: string
  companyLength: string | undefined
  fullName: string
  logoff: () => void
}

export const HeaderSettings: React.FC<Props> = ({
  initialState,
  onChange,
  wrapperRef,
  role,
  email,
  companyLength,
  fullName,
  logoff
}) => {
  const [open, setOpen] = useState(initialState)
  const firstLetter = fullName.charAt(0)

  const [openModal, setOpenModal] = useState<{
    open: boolean
    type: ModalType
  }>({ open: false, type: 'company' })
  const { t } = useTranslation('headerSettings')

  useEffect(() => {
    setOpen(initialState)
  }, [initialState])

  useOutsideAlerter(wrapperRef, (target: HTMLElement) => {
    const isNavMobile =
      target?.id === 'navigation-widget-mobile' ||
      (target.parentElement as HTMLElement)?.id === 'navigation-widget-mobile'

    if (openModal.open) {
      return
    }

    if (!isNavMobile) {
      setOpen(false)
      onChange(false)
    }
  })

  const onCloseModal = () => {
    setOpenModal({ open: false, type: 'company' })
    setOpenModal({ open: false, type: 'workspace' })
  }
  return (
    <div className={`dropdown-box header-dropdown ${open ? 'active' : ''}`}>
      <div className='dropdown-navigation header-settings-dropdown'>
        <div className='dropdown-navigation-header'>
          <Link
            to='/my-profile'
            onClick={() => {
              setOpen(false)
              onChange(false)
            }}
          >
            <Avatar>{firstLetter}</Avatar>
            <p className='user-status-title'>
              <strong>
                {t('hello')}, {fullName}!
              </strong>
            </p>
          </Link>
          <p className='user-status-text small'>{role}</p>
          <p className='user-status-text small'>{email}</p>
          <div className='dropdown-container-button'>
            <div className='dropdown-navigation-button'>
              <CardMenu
                title={t('company')}
                endIcon={<ChevronRightIcon className='card-plan-icon-menu' />}
                onClick={e => {
                  e.preventDefault()
                  setOpenModal({ open: true, type: 'company' })
                }}
                notification={`+${companyLength}`}
              />
            </div>
            <div className='dropdown-navigation-button'>
              <CardMenu
                title={t('sections.settings')}
                endIcon={
                  <SettingsRoundedIcon className='card-plan-icon-menu' />
                }
                route={'/settings'}
              />
            </div>
            <div onClick={logoff} className='dropdown-navigation-button'>
              <CardMenu
                title={t('leave')}
                endIcon={
                  <PowerSettingsNewRoundedIcon className='card-plan-icon-menu' />
                }
              />
            </div>
          </div>
        </div>
      </div>

      <ChangeEnvironmentModal
        open={openModal.open}
        onClose={onCloseModal}
        customClass='ws-modal'
        variant={openModal.type}
      />
    </div>
  )
}
