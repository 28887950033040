import { useTheme } from 'context/ThemeContext/ThemeContext'
import { Theme } from 'context/ThemeContext/ThemeContext.config'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
export const useThemeBne = () => {
  const { dispatch } = useStore()
  const { setTheme } = useTheme()
  const bne = false
  const defaultWorkspace = {
    name: 'Integração',
    uuid: '664ba1156e77e39286817ee2'
  }

  const setThemeBne = () => {
    setTheme(Theme.BNE)
    dispatch(authActions.setCurrentWorkspace(defaultWorkspace))
    window.localStorage.setItem('Workspace-Tenant', defaultWorkspace.uuid)
  }

  return {
    setThemeBne,
    bne
  }
}
