import React from 'react'

export const SpainFlag: React.FC = () => (
  <svg
    width='30'
    height='31'
    viewBox='0 0 30 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_4269_16837)'>
      <path
        d='M0 15.4983C0 17.3331 0.330059 19.0907 0.932988 20.7156L15 22.02L29.067 20.7156C29.6699 19.0907 30 17.3331 30 15.4983C30 13.6635 29.6699 11.9059 29.067 10.2809L15 8.97656L0.932988 10.2809C0.330059 11.9059 0 13.6635 0 15.4983H0Z'
        fill='#FFDA44'
      />
      <path
        d='M29.0676 10.2826C26.9482 4.57068 21.4501 0.5 15.0006 0.5C8.55113 0.5 3.05299 4.57068 0.933594 10.2826H29.0676Z'
        fill='#D80027'
      />
      <path
        d='M0.933594 20.7188C3.05299 26.4307 8.55113 30.5014 15.0006 30.5014C21.4501 30.5014 26.9482 26.4307 29.0676 20.7188H0.933594Z'
        fill='#D80027'
      />
    </g>
    <defs>
      <clipPath id='clip0_4269_16837'>
        <rect
          width='30'
          height='30'
          fill='white'
          transform='translate(0 0.5)'
        />
      </clipPath>
    </defs>
  </svg>
)
