/* eslint-disable indent */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as AddIcon } from 'assets/images/icons/add-circle-outline-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning-icon.svg'
import { Button } from 'components/Button/Button'
import { CustomQuestions } from 'components/CustomQuestions/CustomQuestions'
import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop'
import { GoHomeButton } from 'components/GoHomeButton/GoHomeButton'
import { Loading } from 'components/Loading/Loading'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import { JobFormContainer } from 'containers/Jobs/JobForm/JobFormContainer'
import {
  ValidatewDateOptions,
  useDateValidation
} from 'hooks/useDateValidation'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { jobs, users } from 'services/api/'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import { dateToTimestamp } from 'utils/date'
import { convertUserToUserSchema, filterUsersByRole } from 'utils/users.utils'

import styles from './EditContainer.module.scss'
import editContainerAnalytics from './editContainerAnalytics'

export type FormData = {
  name: string
  city: string | null
  deadline: string
  active: boolean
  hiring_workflow: string
  budget: number | string
  job_group_uuid: string
  job_group: string
  vacancyAllocation: string
  quantity: number
  description: string
  tags: Array<string>
  recruiters_team: Array<string>
  managers_team: Array<string>
}

export type ObjectError = {
  message: string
  errors: {
    [key: string]: string[]
  }
}

export type NewQuestions = {
  uuid: string
  description: string
  eliminate: boolean
  type: 'multiple_choice' | 'selectable_multiple_choice' | 'open_ended'
  answers: [
    {
      description: string
      right_answer: boolean
    }
  ]
}

export const EditContainer: React.FC = () => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const { validateDate, errors: dateError } = useDateValidation()
  const [userPage, setUserPage] = useState(1)
  const [recruiters, setRecruiters] = useState<Array<UserSchema>>([])
  const [managers, setManagers] = useState<Array<UserSchema>>([])
  const [formData, setFormData] = useState({} as FormData)
  const [jobData, setJobData] = useState<Job>({} as Job)
  const { dispatch } = useStore()
  const [questions, setQuestions] = useState([] as Questions[])
  const [newQuestions, setNewQuestions] = useState([] as Array<NewQuestions>)
  const [questionsToUpdate, setQuestionsToUpdate] = useState([] as any)
  const [deleteQuestions, setDeleteQuestions] = useState([] as Array<string>)
  const [applications, setApplications] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [updateQuestionsError, setUpdateQuestionsError] = useState(false)
  const [questionsErrors, setQuestionsErrors] = useState<Array<boolean>>([])
  const [updateQuestionsErrors, setUpdateQuestionsErrors] = useState<boolean[]>(
    []
  )
  const [loading, setLoading] = useState(false)
  const [formValidation, setFormValidation] = useState(false)
  const [notAuthorized, setNotAuthorized] = useState(false)

  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string; name: string }>()

  const { t } = useTranslation('job')
  const { t: tNotification } = useTranslation('notifications')

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  useEffect(() => {
    const hasError = questionsErrors.filter(el => el)
    if (hasError.length > 0) setDisableSubmit(true)
    else setDisableSubmit(false)
  }, [questionsErrors])

  useEffect(() => {
    const hasError = updateQuestionsErrors.filter(el => el)
    if (hasError.length > 0) setUpdateQuestionsError(true)
    else setUpdateQuestionsError(false)
  }, [updateQuestionsErrors])

  const fetchApplications = async () => {
    try {
      const [error, data] = await jobs.listApplications({
        job_uuid: uuid,
        page: 1,
        per_page: 100
      })

      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }

      if (data) {
        data.length > 0 && setApplications(true)
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const updateJob = async () => {
    try {
      setLoading(true)

      const city =
        formData.vacancyAllocation === 'remote' ||
        formData.vacancyAllocation === 'unspecified'
          ? null
          : formData.city

      const invalidDeadline =
        formData.deadline &&
        formData.deadline.trim() !== '//' &&
        !validateDate(formData.deadline, ValidatewDateOptions.DEADLINE)

      if (invalidDeadline)
        throw new Error(
          dateError.invalidDate || t('errors:deadlineInvalidFormat')
        )

      const formDataTreated: UpdateJobPayload = {
        ...formData,
        deadline:
          formData.deadline && formData.deadline.trim() !== '//'
            ? dateToTimestamp(formData.deadline)
            : 0,
        city
      }

      const [error] = await jobs.update(formDataTreated, uuid || '')

      if (error) {
        setLoading(false)
        if (typeof error === 'object') {
          return showNotification({
            message: t('errors:requiredField'),
            type: 'error'
          })
        } else {
          if (error === 'This feature has already reached the limit.') {
            return showNotification({
              message: t('errors:limitReached'),
              type: 'error'
            })
          } else {
            return showNotification({
              message: error,
              type: 'error'
            })
          }
        }
      }

      await createQuestions()
      await updateQuestions()
      await removeQuestions()

      showNotification({
        message: t('updatedMessage'),
        type: 'success'
      })
      setLoading(false)
      editContainerAnalytics(
        { ...formData, questions: newQuestions },
        { ...jobData, questions }
      )

      navigate('/jobs')
    } catch (error: unknown) {
      setLoading(false)
      const errorMessages = (error as Error).message
      showNotification({
        message: dateError.invalidDate || errorMessages,
        type: 'error'
      })
    }
  }

  const removeQuestions = () => {
    try {
      deleteQuestions.map(async el => {
        const [error] = await jobs.deleteQuestions(uuid, el)

        if (error) {
          return showNotification({
            message: (error as ObjectError).message,
            type: 'error'
          })
        }
        return 0
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const fetchJobData = async () => {
    setLoading(true)
    try {
      const [error, data] = await jobs.read(uuid)
      if (data) {
        // Integração com BNE não informa o job_group_uuid mas sim o job_group
        // por isso é necessário buscar o job_group_uuid na API para atualizar o job
        let job_group_uuid
        if (!data.job_group_uuid && data.job_group) {
          // Não tem o uuid mas tem o nome do job_group
          const [error, jobGroup] = await jobs.job_group_search(data.job_group)
          if (jobGroup) {
            // Pega o uuid do primeiro job_group encontrado com o nome informado
            job_group_uuid = jobGroup[0].uuid
          } else {
            job_group_uuid = data.job_group_uuid
          }
        }

        setJobData({
          ...data,
          ...(job_group_uuid && { job_group_uuid }),
          vacancyAllocation:
            data.vacancyAllocation === null
              ? 'presential'
              : data.vacancyAllocation,
          city: data.city === null || data.city === undefined ? '' : data.city
        })
        const obj = {
          name: data.name,
          description: data.description,
          budget: data.budget,
          city: data.city === undefined || data.city === null ? '' : data.city,
          deadline: data.deadline,
          job_group: data.job_group,
          job_group_uuid,
          vacancyAllocation:
            data.vacancyAllocation === null
              ? 'presential'
              : data.vacancyAllocation,
          quantity: data.quantity,
          tags: data.tags,
          managers_team: data.managers_team.map(el => el.uuid),
          recruiters_team: data.recruiters_team.map(el => el.uuid)
        }

        setFormData({ ...formData, ...obj })
      }
      if (error) {
        let message = (error as ObjectError).message
        if (error === 'Not authorized, please check the workspace') {
          setNotAuthorized(true)
          message = tNotification(`lugarh.error.${error}`)
        }
        return showNotification({ message, type: 'error' })
      }
    } catch (error: unknown) {
      const messageError = (error as Error).message
      showNotification({
        message: messageError,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchQuestions = async () => {
    try {
      const [error, data] = await jobs.listQuestions(uuid)
      if (data) {
        setQuestions(data)
      }
      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const createQuestions = async () => {
    try {
      newQuestions.map(async el => {
        const payload: any = { ...el }
        delete payload.uuid

        if (payload.answers) {
          payload.answers.map(el => {
            delete el.uuid
            delete el.updated_at
            return delete el.created_at
          })

          delete payload.answers.created_at

          delete payload.answers.updated_at
        }

        if (payload.type === 'open_ended') {
          payload.answers = [] as unknown as [
            { description: string; right_answer: boolean }
          ]
        }

        const [error] = await jobs.createQuestions({ uuid, data: payload })

        if (error) {
          return showNotification({
            message: (error as ObjectError).message,
            type: 'error'
          })
        }
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const fetchUsers = async () => {
    try {
      const [error, data] = await users.read({ page: userPage, per_page: 10 })
      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }

      if (data?.data.length) {
        const userData = convertUserToUserSchema(data.data)
        const { filteredRecruiters, filteredManagers } = filterUsersByRole({
          data: userData,
          filterActiveUsers: true
        })

        setRecruiters([...recruiters, ...filteredRecruiters])
        setManagers([...managers, ...filteredManagers])
      }

      if (data?.data.length === 10) {
        setUserPage(userPage + 1)
      }
    } catch (error: unknown) {
      const errorMessages = (error as Error).message
      showNotification({ message: errorMessages, type: 'error' })
    }
  }

  const updateQuestions = async () => {
    try {
      questionsToUpdate.map(async el => {
        const payload: any = { ...el }
        const questionUUID = payload.uuid
        delete payload.uuid
        delete payload.type

        payload.answers?.map(el => {
          delete el.uuid
          delete el.updated_at
          if (el.right_answer === undefined) el.right_answer = false
          return delete el.created_at
        })

        delete payload.answers?.created_at

        delete payload.answers?.updated_at
        const [error] = await jobs.updateQuestions(uuid, questionUUID, payload)

        if (error) {
          return showNotification({
            message: (error as ObjectError).message,
            type: 'error'
          })
        }
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  useEffect(() => {
    fetchUsers()
    fetchJobData()
    fetchQuestions()
    fetchApplications()
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [userPage])

  const handleValidationChange = (value: boolean) => {
    setFormValidation(value)
  }

  if (loading) return <Loading />
  if (notAuthorized) return <GoHomeButton />

  return (
    <>
      {dontShowOnGeralWorkspace && (
        <>
          <WidgetBox>
            <JobFormContainer
              value={{
                name: jobData.name,
                description: jobData.description,
                budget: jobData.budget,
                city: jobData.city,
                deadline: jobData.deadline,
                active: jobData.active,
                job_group_uuid: jobData.job_group_uuid,
                job_group: jobData.job_group,
                vacancyAllocation: jobData.vacancyAllocation,
                quantity: jobData.quantity,
                tags: jobData.tags
              }}
              onChange={values => {
                setFormData({ ...formData, ...values })
              }}
              onValidationChange={value => handleValidationChange(value)}
            />
          </WidgetBox>
          <WidgetBox>
            <p className='title'>{t('questions')}</p>
            {applications && (
              <div className={styles.warning}>
                <WarningIcon />
                <p>{t('questionsInfo')}</p>
              </div>
            )}

            {questions.map((el, index) => {
              return (
                <CustomQuestions
                  onError={e => {
                    const errors = [...updateQuestionsErrors]
                    errors[index] = e
                    setUpdateQuestionsErrors(errors)
                  }}
                  key={el.uuid}
                  value={questions[index]}
                  edit={!!uuid}
                  hasApplications={applications}
                  blockButtons={
                    questions.length <= 1
                      ? { up: true, down: true }
                      : index === 0
                        ? { up: true, down: false }
                        : index + 1 === questions.length
                          ? { up: false, down: true }
                          : { up: false, down: false }
                  }
                  onChange={e => {
                    if (e.description) {
                      const arr = [...questions]
                      arr[index] = e as Questions
                      arr[index].uuid = el.uuid
                      const updateArr = [...questions]
                      updateArr[index] = e as Questions
                      updateArr[index].uuid = el.uuid
                      setQuestionsToUpdate(updateArr)
                      setQuestions(arr)
                    }
                  }}
                  onDeleteQuestion={() => {
                    const arr = [...questions]
                    const deleteQ = arr.splice(index, 1)
                    const errors = [...updateQuestionsErrors]
                    delete errors[index]
                    setUpdateQuestionsErrors(errors)
                    setDeleteQuestions([...deleteQuestions, deleteQ[0].uuid])
                    setQuestions(arr)
                  }}
                  onDuplicateQuestion={() => {
                    const arr: any = [...questions]
                    arr[index].uuid = (+new Date()).toString()
                    const newArr = [...newQuestions]
                    newArr.push(arr[index])
                    setNewQuestions(newArr as NewQuestions[])
                  }}
                  onMoveDownQuestion={() => {
                    if (index < questions.length - 1) {
                      const arr = [...questions]
                      arr[index + 1] = questions[index]
                      arr[index] = questions[index + 1]
                      setQuestions(arr)
                    }
                  }}
                  onMoveUpQuestion={() => {
                    if (index !== 0) {
                      const arr = [...questions]
                      arr[index - 1] = questions[index]
                      arr[index] = questions[index - 1]
                      setQuestions(arr)
                    }
                  }}
                />
              )
            })}

            {!applications && (
              <>
                {newQuestions.map((el, index) => {
                  return (
                    <CustomQuestions
                      onError={e => {
                        const errors = [...questionsErrors]
                        errors[index] = e
                        setQuestionsErrors(errors)
                      }}
                      key={el.uuid}
                      value={newQuestions[index]}
                      onChange={e => {
                        if (e.description) {
                          const arr = [...newQuestions]
                          arr[index] = { ...arr[index], ...(e as Questions) }
                          setNewQuestions(arr)
                        }
                      }}
                      blockButtons={
                        newQuestions.length <= 1
                          ? { up: true, down: true }
                          : index === 0
                            ? { up: true, down: false }
                            : index + 1 === newQuestions.length
                              ? { up: false, down: true }
                              : { up: false, down: false }
                      }
                      onDeleteQuestion={() => {
                        const arr = [...newQuestions]
                        const errors = [...questionsErrors]
                        delete errors[index]
                        setQuestionsErrors(errors)
                        arr.splice(index, 1)
                        setNewQuestions(arr)
                      }}
                      onDuplicateQuestion={() => {
                        const item = { ...newQuestions[index] }
                        item.uuid = (+new Date()).toString()
                        const arr = [...newQuestions]
                        arr.push(item)
                        setNewQuestions(arr as NewQuestions[])
                      }}
                      onMoveDownQuestion={() => {
                        if (index < newQuestions.length - 1) {
                          const arr = [...newQuestions]
                          arr[index + 1] = newQuestions[index]
                          arr[index] = newQuestions[index + 1]
                          setNewQuestions(arr)
                        }
                      }}
                      onMoveUpQuestion={() => {
                        if (index !== 0) {
                          const arr = [...newQuestions]
                          arr[index - 1] = newQuestions[index]
                          arr[index] = newQuestions[index - 1]
                          setNewQuestions(arr)
                        }
                      }}
                    />
                  )
                })}

                <div className='row d-flex justify-content-end'>
                  <div className='col d-flex justify-content-end'>
                    <Button
                      className='primary add-button'
                      onClick={() => {
                        setNewQuestions(oldState => {
                          const arr = [...oldState]
                          arr.push({
                            uuid: (+new Date()).toString(),
                            description: '',
                            eliminate: false,
                            type: 'open_ended',
                            answers: [
                              {
                                description: '',
                                right_answer: false
                              }
                            ]
                          })
                          return arr
                        })
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </div>
              </>
            )}
          </WidgetBox>

          {recruiters?.length ? (
            <WidgetBox>
              <DragAndDrop
                boardID='recruiters'
                onChange={e => {
                  const team = e.team.map(el => {
                    return el.el.uuid
                  })

                  setFormData({ ...formData, recruiters_team: team })
                }}
                board1Header={t('recruiters')}
                board2Header={t('recruitersTeam')}
                availables={recruiters
                  .filter(el => {
                    const team = jobData?.recruiters_team?.map(el => {
                      return el.uuid
                    })

                    return !team?.includes(el.uuid)
                  })
                  .map(el => {
                    return { text: `${el.firstname} ${el.lastname}`, el }
                  })}
                team={jobData?.recruiters_team?.map(el => {
                  return { text: `${el.firstname} ${el.lastname}`, el }
                })}
              />
            </WidgetBox>
          ) : null}
          {managers.length ? (
            <WidgetBox>
              <DragAndDrop
                boardID='managers'
                onChange={e => {
                  const team = e.team.map(el => {
                    return el.el.uuid
                  })

                  setFormData({ ...formData, managers_team: team })
                }}
                board1Header={t('managers')}
                board2Header={t('managersTeam')}
                availables={managers
                  .filter(el => {
                    const team = jobData?.managers_team?.map(el => {
                      return el.uuid
                    })

                    return !team?.includes(el.uuid)
                  })
                  .map(el => {
                    return { text: `${el.firstname} ${el.lastname}`, el }
                  })}
                team={jobData?.managers_team?.map(el => {
                  return { text: `${el.firstname} ${el.lastname}`, el }
                })}
              />
            </WidgetBox>
          ) : null}
          <Button
            className='secondary mt-3'
            onClick={updateJob}
            disabled={
              disableSubmit ||
              updateQuestionsError ||
              loading ||
              !formValidation
            }
            loading={loading}
          >
            {t('save')}
          </Button>
        </>
      )}
    </>
  )
}
