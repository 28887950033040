import React, { useState, useRef } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import MenuIcon from '@mui/icons-material/Menu'
import { Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from 'assets/images/logo/lugarh-new-brand.svg'
import { BrazilFlag } from 'components/LanguagePicker/lugarh/Icons/BrazilFlag'
import { SpainFlag } from 'components/LanguagePicker/lugarh/Icons/SpainFlag'
import { UnitedStatesFlag } from 'components/LanguagePicker/lugarh/Icons/UnitedStatesFlag'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { SelectLanguage } from 'components/SelectLanguage/SelectLanguage'
import useStore from 'store'
import { AsideMenuActions } from 'store/asideMenu'
import * as authActions from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import { CheckCallbackUrl } from 'utils/partnerCallbackUrl'

import { AsideMenu } from '../AsideMenu/AsideMenu'
import { HeaderSettings } from '../HeaderSettings/HeaderSettings'
import { SearchBar } from '../SearchBar/SearchBar'

import './Header.scss'

import i18n from 'i18n'
import { TooltipWrapper } from 'components/Tooltip/lugarh/TooltipWrapper'

export const Header: React.FC = () => {
  const headerSettingsRef = useRef<HTMLDivElement>(null)
  const headerLanguageRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [openLanguage, setOpenLanguage] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation('user')
  const { dispatch, selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const user = selectors.auth.user()
  const fullName = selectors.auth.fullName()
  const firstLetter = fullName.charAt(0)
  const role = t(`role.${user?.role}`)
  const companysLength = user?.companies?.length
  const handleOpenSettings = () => {
    setOpen(!open)
    setOpenLanguage(false)
  }

  const handleOpenLanguage = () => {
    setOpenLanguage(!openLanguage)
    setOpen(false)
  }
  const getFlagComponent = () => {
    switch (i18n.language) {
      case 'pt-BR':
        return <BrazilFlag />
      case 'es':
        return <SpainFlag />
      case 'en':
      default:
        return <UnitedStatesFlag />
    }
  }
  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const logoff = () => {
    selectors.auth.clearUser()

    dispatch(authActions.logoff())

    if (CheckCallbackUrl()) {
      window.localStorage.setItem('removeCallbackUrl', 'true')
    } else {
      document.location.reload()
    }
  }

  const CustomTheme = React.lazy(() =>
    import('../CustomTheme/CustomTheme').then(({ CustomTheme }) => ({
      default: CustomTheme
    }))
  )
  const expandMenu = () => {
    const newExpandState = !expandedMenu
    dispatch(AsideMenuActions.setExpanded(newExpandState))
    window.localStorage.setItem(
      'asideMenuExpanded',
      JSON.stringify(newExpandState)
    )
  }
  const ThemeSelector = ({ children }) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {user?.themeCustomization && <CustomTheme />}
        </React.Suspense>
        {children}
      </>
    )
  }

  const generateReferralLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup?associate=${user?.uuid}`
    )

    showNotification({
      message: t('copyLinkSuccessMessage'),
      type: 'success'
    })
  }

  return (
    <>
      <ThemeSelector>
        <header className='header' data-testid='recrud-header'>
          <div className='header-actions'>
            <div className='header-brand'>
              <div
                className={`expand-button ${
                  expandedMenu ? 'expand-button-right' : ''
                }`}
                onClick={() => expandMenu()}
              >
                {expandedMenu ? (
                  <CloseIcon className='expand-button__icon' />
                ) : (
                  <MenuIcon className='expand-button__icon' />
                )}
              </div>
              <div className='logo'>
                <a href='' onClick={() => navigate('/jobs')}>
                  <Logo />
                </a>
              </div>
            </div>
          </div>
          <div className='header-actions' onClick={() => setOpen(!open)}>
            <div className='mobilemenu-trigger navigation-widget-mobile-trigger'>
              <div className='burger-icon inverted'>
                <div className='burger-icon-bar'></div>
                <div className='burger-icon-bar'></div>
                <div className='burger-icon-bar'></div>
              </div>
            </div>
          </div>

          <SearchBar />

          {/* <!-- HEADER ACTIONS --> */}
          <div className='header-actions'>
            <section className='headers_options'>
              <PermissionAgent
                module='indicate-button'
                actions={['view']}
                onFail='hidden'
                Component={() => (
                  <div className='indicate-btn'>
                    <a onClick={() => generateReferralLink()}>
                      {t('indicationButton')}
                    </a>
                  </div>
                )}
              />
              <div className='action-list dark tutorial-step-5'>
                <div className='action-list-item-wrap d-flex'>
                  <div
                    className={`header-avatar-container ${openLanguage ? 'active' : ''}`}
                    onClick={handleOpenLanguage}
                  >
                    {getFlagComponent()}
                    <div className='header-avatar-container__icon'>
                      <KeyboardArrowDownRoundedIcon
                        className={openLanguage ? 'rotated' : ''}
                      />
                    </div>
                  </div>
                  <div ref={headerSettingsRef}>
                    <div
                      className={`header-avatar-container ${open ? 'active' : ''}`}
                      onClick={handleOpenSettings}
                    >
                      <div
                        className={`header-avatar-container__avatar header-avatar-container__avatar--${user?.role}`}
                      >
                        <Avatar>{firstLetter}</Avatar>
                      </div>
                      <div className='header-avatar-container__info'>
                        <span className='header-avatar-container__info-name default-font'>
                          {fullName}
                        </span>

                        <TooltipWrapper
                          key={`tooltip-${user?.currentCompany?.name}`}
                          placement='bottom'
                          title={user?.currentCompany?.name}
                        >
                          <span className='header-avatar-container__info-company default-font'>
                            {user?.currentCompany?.name}
                          </span>
                        </TooltipWrapper>
                      </div>
                      <div className='header-avatar-container__icon'>
                        <KeyboardArrowDownRoundedIcon
                          className={open ? 'rotated' : ''}
                        />
                      </div>
                    </div>
                    <SelectLanguage
                      initialState={openLanguage}
                      wrapperRef={headerLanguageRef}
                      onChange={() => setOpenLanguage(!openLanguage)}
                    />
                    <HeaderSettings
                      logoff={logoff}
                      fullName={fullName}
                      role={role}
                      email={user?.email}
                      companyLength={companysLength}
                      initialState={open}
                      wrapperRef={headerSettingsRef}
                      onChange={() => setOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </header>
        <span>{t('here')}</span>
        <AsideMenu
          fullName={fullName}
          role={role}
          logoff={logoff}
          open={open}
          onChange={() => setOpen(false)}
          returnIconButton={
            user?.themeCustomization ? user?.themeCustomization.icon : null
          }
        />
      </ThemeSelector>
    </>
  )
}
