import * as AuthTypes from './types'

export const InitialState: AuthTypes.State = {
  token: null,
  refreshToken: null,
  expiresIn: null,
  refreshExpiresIn: null,
  loading: false,
  error: null,
  loginError: null,
  registerError: null,
  keepConnected: false,
  user: null
}

export function reducer(
  state = InitialState,
  action: AuthTypes.Actions
): AuthTypes.State {
  switch (action.type) {
    case AuthTypes.Types.CLEAR_ERRORS:
      return { ...state, error: null, loginError: null, registerError: null }

    case AuthTypes.Types.ENABLE_LOADING:
      return { ...state, loading: true }

    case AuthTypes.Types.DISABLE_LOADING:
      return { ...state, loading: false }

    case AuthTypes.Types.SET_TOKEN:
      return { ...state, ...action.payload }

    case AuthTypes.Types.SET_USER:
      return { ...state, user: action.payload }

    case AuthTypes.Types.SET_CURRENT_WORKSPACE:
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            currentWorkspace: action.payload
          }
        }
      } else {
        return state
      }
    case AuthTypes.Types.SET_CURRENT_COMPANY:
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            currentCompany: action.payload
          }
        }
      } else {
        return state
      }

    case AuthTypes.Types.SET_COMPANY_ID:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user: { ...state.user, company: { uuid: action.payload } }
      }

    case AuthTypes.Types.SET_COMPANY:
      return {
        ...state,
        user: { ...state.user, company: action.payload } as User
      }

    case AuthTypes.Types.SET_KEEP_CONNECTED:
      return { ...state, keepConnected: action.payload }

    case AuthTypes.Types.SET_ERROR:
      return { ...state, registerError: null, loginError: action.payload }

    case AuthTypes.Types.SET_LOGIN_ERROR:
      return { ...state, registerError: null, loginError: action.payload.error }

    case AuthTypes.Types.SET_REGISTER_ERROR:
      return { ...state, loginError: null, registerError: action.payload.error }

    case AuthTypes.Types.LOGOFF:
      return {
        ...state,
        token: null,
        refreshToken: null,
        expiresIn: null,
        refreshExpiresIn: null,
        loading: false,
        loginError: null,
        registerError: null,
        user: null
      }

    default:
      return state
  }
}
