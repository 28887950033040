import React, { useEffect } from 'react'

import { Footer as FooterBNE } from 'components/Footer/bne/Footer'
import { Header as HeaderBNE } from 'components/Header/bne/Header'
import { Header } from 'components/Header/Header'
import { useTheme } from 'context/ThemeContext/ThemeContext'
import { Theme } from 'context/ThemeContext/ThemeContext.config'
import './DefaultAppStructure.style.scss'

export const DefaultAppStructure: React.FC = ({ children }) => {
  const { theme } = useTheme()

  if (theme === Theme.BNE)
    return (
      <div className='app-structure'>
        <HeaderBNE />
        <div className='app-structure__container'>{children}</div>
        <FooterBNE />
      </div>
    )

  return (
    <div className='theme__app-structure'>
      <Header />
      {children}
    </div>
  )
}
