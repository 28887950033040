import * as Yup from 'yup'

export const editUserFormValidationSchemaStepOne = (isEdit: boolean) =>
  Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .strict()
      .matches(
        /^(?!\s*$).+/,
        'The first name cannot be empty or contain only spaces.'
      )
      .required('Required field'),
    lastname: Yup.string()
      .trim()
      .strict()
      .matches(
        /^(?!\s*$).+/,
        'The last name cannot be empty or contain only spaces.'
      )
      .required('Required field'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Required field'),
    password: !isEdit
      ? Yup.string()
          .required('Required field')
          .min(6, 'Password must have at least 6 characters')
      : Yup.string(),
    role: Yup.string().required('Required field')
  })

export const editUserFormValidationSchemaStepTwo = Yup.object().shape({
  workspaces: Yup.array()
    .min(1, 'Select at least one workspace')
    .required('Workspace is a required field')
})
