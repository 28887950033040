import React from 'react'

import './Footer.style.scss'
import { useTranslation } from 'react-i18next'

export const Footer: React.FC = () => {
  const { t } = useTranslation('footer')
  return (
    <div className='theme-bne bne-footer__container bne-font txt-m'>
      {t('rights')}
    </div>
  )
}
