import React, { useState, useRef, useEffect } from 'react'

import bneGif from 'assets/images/avatar/bne.gif'
import { ReactComponent as Logo } from 'assets/images/logo/logo-bne.svg'

import './Header.style.scss'
import { Home, Public, Search, Settings } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'

import { LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { t } = useTranslation('header')

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenu(false)
      }
    }

    if (openMenu) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openMenu])

  return (
    <div className='theme-bne header-bne__container'>
      <Logo className='header-bne__logo-bne' />
      <div className='header-bne__links bne-font txt-s'>
        <a className='bne-font txt-s header-bne__link' href='#'>
          <Home /> {t('bne.home')}
        </a>
        <a
          href='https://www.bne.com.br/pesquisa-de-curriculos?'
          className='bne-font txt-s header-bne__link'
        >
          <Search /> {t('bne.searchResume')}
        </a>
        <a
          href='/jobs'
          className={`bne-font txt-s header-bne__link${location.pathname === '/jobs' ? '--active' : ''}`}
        >
          <Public /> {t('bne.myJobs')}
        </a>
        <a
          href='https://www.bne.com.br/cia/configuracoes-empresa'
          className='bne-font txt-s header-bne__link'
        >
          <Settings /> {t('bne.settings')}
        </a>
      </div>
      <ThemeButton
        template={ThemeButtonTemplate.TEXT_ONLY}
        title='Fluxo Bug ATS'
        onClick={() => setOpenMenu(true)}
        customClass='bne-font txt-m txt-normal header-bne__user'
      >
        <span className=''>Fluxo Bug ATS</span>
      </ThemeButton>

      {openMenu && (
        <div className='header-bne__menu' ref={menuRef}>
          <div className='header-bne__menu-content'>
            <img src={bneGif} className='header-bne__avatar' alt='Avatar' />
            <span className='bne-font txt-normal'>Fluxo Bug ATS</span>
            <span className='bne-font txt-s'>gugaats@malinator.com</span>
            <div className='header-bne__upgrade'>
              <div>
                <img src={bneGif} alt='' className='header-bne__avatar-mini' />
              </div>
              <div>
                <span className='header-bne__text'>Fluxo Bug ATS</span>
                <LinearProgress
                  color='success'
                  variant='determinate'
                  value={10}
                />
              </div>
              <div>
                <ThemeButton
                  template={ThemeButtonTemplate.PRIMARY}
                  title='Upgrade'
                  customClass='header-bne__button-upgrade'
                />
              </div>
            </div>
            <div className='header-bne__footer-menu'>
              <ThemeButton
                title={t('bne.changeCompany')}
                template={ThemeButtonTemplate.TEXT_ONLY}
                customClass='bne-font txt-normal txt-bold txt-bold header-bne__footer-button'
              />
              <ThemeButton
                title={t('bne.exit')}
                template={ThemeButtonTemplate.TEXT_ONLY}
                customClass='header-bne__footer-button--exit bne-font txt-normal txt-bold txt-bold'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
