import React, { useState } from 'react'

import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg'
import bneIcon from 'assets/images/icons/bne.png'
import { ReactComponent as CandidatesIcon } from 'assets/images/icons/candidates.svg'
import { ReactComponent as OverviewIcon } from 'assets/images/icons/home.svg'
import { ReactComponent as JobsIcon } from 'assets/images/icons/jobs.svg'
import { EmailButton } from 'components/EmailButton/EmailButton'
import { ChangeEnvironmentModal } from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { ADMIN_PERMISSIONS } from 'contants'
import i18n from 'i18n'
import useStore from 'store'
import { AsideMenuActions } from 'store/asideMenu'

import './AsideMenu.scss'
import { Avatar } from '../Avatar/Avatar'

import { LugarhAdmIcon } from 'components/Icons/lugarh/LugarhAdmIcon'
import { TooltipWrapper } from 'components/Tooltip/lugarh/TooltipWrapper'

type Props = {
  open: boolean
  onChange: (value: boolean) => void
  logoff: () => void
  fullName: string
  role: string
  returnIconButton: string | null
}

export const AsideMenu: React.FC<Props> = ({
  open,
  onChange,
  fullName,
  role,
  logoff
}) => {
  const { t } = useTranslation('headerSettings')
  const { selectors, dispatch } = useStore()
  const user = selectors.auth.user()
  const location = useLocation()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [hasPermission, setHasPermission] = useState<boolean>(true)
  const firstLetter = user?.currentWorkspace?.name
    ?.split(' ')
    .map(word => word.charAt(0))
    .slice(0, 2)
    .join('')
    .toUpperCase()

  const setPermission = (permission: boolean) => {
    setHasPermission(permission)
  }
  const callbackUrl =
    window.localStorage.getItem('partnerUrlCallback') &&
    window.localStorage.getItem('partnerUrlCallback') !== null
      ? window.localStorage.getItem('partnerUrlCallback')
      : null

  const handleCloseSidebar = () => {
    if (openModal) return
    dispatch(AsideMenuActions.setExpanded(false))
    window.localStorage.setItem('asideMenuExpanded', 'false')
  }
  return (
    <>
      <nav
        className={`navigation-widget navigation-widget-desktop sidebar left delayed ${
          expandedMenu ? 'active' : 'closed'
        }`}
        onMouseLeave={handleCloseSidebar}
      >
        <ul className={`menu ${expandedMenu ? '' : 'small'}`}>
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : 'Workspace'}
          >
            <span
              className={`menu-item-link area ${!expandedMenu ? 'text-tooltip-tfr' : ''}`}
            >
              {expandedMenu ? (
                'Workspaces'
              ) : (
                <div className='menu-item-link__workspace'>
                  <div className='menu-item-link__icon'>{firstLetter}</div>
                </div>
              )}
            </span>

            {expandedMenu ? (
              <>
                <div className='workspace-card'>
                  <div className='workspace-card__icon'>{firstLetter}</div>
                  <div className='workspace-card__content'>
                    <TooltipWrapper
                      key={`tooltip-${user?.currentWorkspace?.name}`}
                      placement='right'
                      title={user?.currentWorkspace?.name}
                    >
                      <p className='workspace-card__title'>
                        {user?.currentWorkspace?.name}
                      </p>
                    </TooltipWrapper>

                    <TooltipWrapper
                      key={`tooltip-${user?.company?.slug}`}
                      placement='right'
                      title={user?.company?.slug}
                    >
                      <p className='workspace-card__subtitle'>
                        {user?.company?.slug}
                      </p>
                    </TooltipWrapper>
                  </div>
                </div>
                <button
                  onClick={() => setOpenModal(true)}
                  className='workspace-card__button txt-bold'
                >
                  <ImportExportRoundedIcon className='button__icon' />
                  Trocar de workspace
                </button>
              </>
            ) : null}
          </li>
          <hr className='divider' />
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.jobs')}
            className={`${location.pathname === '/jobs' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/jobs'
              onClick={() => {
                onChange(false)
              }}
            >
              <JobsIcon className='link-icon' />
              {expandedMenu ? t('sections.jobs') : ''}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.candidates')}
            className={`${
              location.pathname === '/candidates-database' ? 'active' : ''
            }`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/candidates-database'
              onClick={() => {
                onChange(false)
              }}
            >
              <PeopleAltIcon className='link-icon' />
              {expandedMenu ? t('sections.candidates') : ''}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.dashboard')}
            className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/dashboard'
              onClick={() => onChange(false)}
            >
              <InsertChartIcon className='link-icon' />
              {expandedMenu ? t('sections.dashboard') : ''}
            </Link>
          </li>
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.admission')}
          >
            <a
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              href={'https://empresa.lugarh.com.br/'}
              target='_blank'
              rel='noreferrer'
            >
              <LugarhAdmIcon />
              {expandedMenu ? t('sections.admission') : null}
            </a>
          </li>
          {callbackUrl !== null && callbackUrl !== '' ? (
            <li id='navigation-widget-mobile' data-tooltip={t('jobPostings')}>
              <a
                className={`menu-item-link ${
                  expandedMenu ? '' : 'text-tooltip-tfr'
                }`}
                href={callbackUrl !== null ? callbackUrl : ''}
              >
                <img
                  src={bneIcon !== null ? bneIcon : ''}
                  className='link-icon'
                />
                {expandedMenu ? t('jobPostings') : ''}
              </a>
            </li>
          ) : (
            ''
          )}
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.settings')}
            className={`${location.pathname === '/settings' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/settings'
              onClick={() => onChange(false)}
            >
              <SettingsIcon className='link-icon' />
              {expandedMenu ? t('sections.settings') : ''}
            </Link>
          </li>
        </ul>
      </nav>

      <nav
        id='navigation-widget-mobile'
        className={`navigation-widget navigation-widget-mobile sidebar left delayed ${
          open ? '' : 'hidden'
        }`}
      >
        <div
          className='navigation-widget-close-button'
          onClick={() => onChange(false)}
        >
          <div className='navigation-widget-close-button-icon icon-back-arrow'>
            <ArrowIcon />
          </div>
        </div>

        <div className='navigation-widget-info-wrap'>
          <div className='navigation-widget-info'>
            <Avatar user={fullName} />

            <p className='navigation-widget-info-title'>
              <Link to='/my-profile'>{fullName}</Link>
            </p>

            <p className='navigation-widget-info-text'>{role}</p>
          </div>
        </div>

        <ul>
          <li
            id='navigation-widget-mobile'
            data-tooltip='Dashboard'
            className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
            <Link to='/dashboard' onClick={() => onChange(false)}>
              <OverviewIcon className='link-icon' />
              {t('sections.dashboard')}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            className={`${location.pathname === '/jobs' ? 'active' : ''}`}
            data-tooltip='Jobs'
          >
            {hasPermission ? (
              <PermissionAgent
                module='job'
                actions={['view']}
                onFail='hidden'
                Component={({ hidden }) =>
                  hidden ? null : (
                    <Link
                      to='/jobs'
                      onClick={() => {
                        onChange(false)
                      }}
                    >
                      <JobsIcon className='link-icon' />
                      {t('sections.jobs')}
                    </Link>
                  )
                }
              />
            ) : (
              <Link to={null} onClick={() => null}>
                <JobsIcon className='link-icon' />
                {t('sections.jobs')}
              </Link>
            )}
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip='Candidates'
            className={`${
              location.pathname === '/candidates-database' ? 'active' : ''
            }`}
          >
            {hasPermission ? (
              <PermissionAgent
                module='candidate'
                actions={['view']}
                setPermission={setPermission}
                onFail='hidden'
                Component={({ hidden }) =>
                  hidden ? null : (
                    <Link
                      to='/candidates-database'
                      onClick={() => onChange(false)}
                    >
                      <CandidatesIcon className='link-icon' />
                      {t('sections.candidates')}
                    </Link>
                  )
                }
              />
            ) : (
              <Link to={null} onClick={() => null}>
                <CandidatesIcon className='link-icon' />
                {t('sections.candidates')}
              </Link>
            )}
          </li>
        </ul>

        <p className='navigation-widget-section-title'>{t('myProfile')}</p>
        <Link
          className='navigation-widget-section-link'
          to='/my-profile'
          onClick={() => onChange(false)}
        >
          {t('profileEdit')}
        </Link>
        <p className='navigation-widget-section-title'>{t('company')}</p>
        <Link
          className='navigation-widget-section-link'
          to='/company-settings'
          onClick={() => onChange(false)}
        >
          {t('companySettings')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to='/hiring-workflow'
          onClick={() => onChange(false)}
        >
          {t('changeCompany')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to=''
          onClick={() => setOpenModal(true)}
        >
          {t('changeWorkspace')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to='/hiring-workflow'
          onClick={() => onChange(false)}
        >
          {t('hiringWorkflows')}
        </Link>

        <PermissionAgent
          module='user'
          actions={ADMIN_PERMISSIONS}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <Link
                className='navigation-widget-section-link'
                to='/team-management'
                onClick={() => onChange(false)}
              >
                {t('manageTeam')}
              </Link>
            )
          }
        />
        <ChangeEnvironmentModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          customClass='ws-modal'
          variant='workspace'
        />
        <p className='navigation-widget-section-title'>{t('session')}</p>
        <a className='navigation-widget-section-link' onClick={logoff}>
          {t('leave')}
        </a>
        <p className='navigation-widget-section-title'>{t('language')}</p>

        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('en-US')
            onChange(false)
          }}
        >
          {` ${t('english')}`}
        </a>
        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('pt-BR')
            onChange(false)
          }}
        >
          {` ${t('portuguese')}`}
        </a>
        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('es')
            onChange(false)
          }}
        >
          {` ${t('spanish')}`}
        </a>
      </nav>

      <div
        className={`overlay ${open ? 'open' : ''}`}
        onClick={() => onChange(false)}
      />

      <PermissionAgent
        module='app'
        actions={['help-button-email']}
        onFail='hidden'
        Component={({ hidden }) =>
          hidden ? null : (
            <EmailButton
              helpButtonSubject={t('helpButtonSubject')}
              helpButtonDescription={t('helpButtonDescription')}
            />
          )
        }
      />
    </>
  )
}
