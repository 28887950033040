import React, { RefObject, useEffect, useState } from 'react'

import { CardMenu } from 'components/CardMenu/CardMenu'
import { BrazilFlag } from 'components/LanguagePicker/lugarh/Icons/BrazilFlag'
import { SpainFlag } from 'components/LanguagePicker/lugarh/Icons/SpainFlag'
import { UnitedStatesFlag } from 'components/LanguagePicker/lugarh/Icons/UnitedStatesFlag'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'
import './SelectLanguage.scss'
import { useStoreNotification } from 'hooks/useStoreNotification'
import i18n from 'i18n'

import { useTranslation } from 'react-i18next'

type Props = {
  initialState: boolean
  wrapperRef: RefObject<HTMLElement>
  onChange: (value: boolean) => void
}

export const SelectLanguage: React.FC<Props> = ({
  initialState,
  onChange,
  wrapperRef
}) => {
  const { t } = useTranslation('modalLanguage')
  const [open, setOpen] = useState(initialState)
  const { showNotification } = useStoreNotification()
  useEffect(() => {
    setOpen(initialState)
  }, [initialState])

  useOutsideAlerter(wrapperRef, (target: HTMLElement) => {
    const isNavMobile =
      target?.id === 'navigation-widget-mobile' ||
      (target.parentElement as HTMLElement)?.id === 'navigation-widget-mobile'

    if (!isNavMobile) {
      setOpen(false)
      onChange(false)
    }
  })

  const handleChangeLanguage = async (language: string) => {
    try {
      await i18n.changeLanguage(language)
      setOpen(false)
      onChange(false)
      showNotification({
        message: t('message.sucess'),
        type: 'success'
      })
    } catch (error) {
      showNotification({
        message: t('message.error'),
        observation: t('message.erroSubtitle'),
        type: 'error'
      })
    }
  }

  return (
    <div className={`dropdown-box header-dropdown ${open ? 'active' : ''}`}>
      <div className='dropdown-navigation header-settings-dropdown'>
        <div className='dropdown-navigation-header'>
          <div className='dropdown-navigation-button'>
            <CardMenu
              title={t('ptBr')}
              startIcon={<BrazilFlag />}
              onClick={() => handleChangeLanguage('pt-BR')}
            />
          </div>
          <div className='dropdown-navigation-button'>
            <CardMenu
              title={t('enUS')}
              startIcon={<UnitedStatesFlag />}
              onClick={() => handleChangeLanguage('en-US')}
            />
          </div>
          <div className='dropdown-navigation-button'>
            <CardMenu
              title={t('es')}
              startIcon={<SpainFlag />}
              onClick={() => handleChangeLanguage('es')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
