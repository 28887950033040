import React from 'react'

import './CardMenu.scss'
import { Link } from 'react-router-dom'

type CardMenuProps = {
  title: string
  route?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  notification?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}
export const CardMenu: React.FC<CardMenuProps> = ({
  title,
  route,
  startIcon,
  endIcon,
  notification,
  onClick
}) => {
  return (
    <Link className='card-plan-menu' to={route || ''} onClick={onClick}>
      <div className='card-plan-content-menu'>
        {startIcon}
        <div className='card-plan-text-menu'>
          <p>{title}</p>
        </div>
        <div className='flex-end'>
          {notification ? (
            <div className='notification'>{notification}</div>
          ) : null}
          {endIcon}
        </div>
      </div>
    </Link>
  )
}
