import React, { useCallback, useEffect, useState } from 'react'

import { DefaultModal } from 'components/Modals/lugarh/Default/DefaultModal'

import './ChangeEnvironmentModal.style.scss'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import SingleSelectableList from 'components/SelectableList/lugarh/SingleSelectableList'
import { useStoreNotification } from 'hooks/useStoreNotification'

import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'

import * as wsApi from 'services/api/workspaces/workspaces'

// import * as companyApi from 'services/api/companies/companies'

import useStore from 'store'
import * as authActions from 'store/auth/actions'
import { Loading } from 'components/Loading/lugarh/Loading'
import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'

export type ModalType = 'company' | 'workspace'
export interface ChangeEnvironmentModalProps {
  open: boolean
  variant: ModalType
  onClose: () => void
  customClass?: string
}

export const ChangeEnvironmentModal: React.FC<ChangeEnvironmentModalProps> = ({
  open,
  variant,
  onClose,
  customClass = ''
}) => {
  const { t } = useTranslation('changeEnvironmentModal')
  const { t: tNotification } = useTranslation('notifications')
  const [data, setData] = useState<any[]>()
  const [selected, setSelected] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { dispatch, selectors } = useStore()
  const { showNotification } = useStoreNotification()
  const navigate = useNavigate()

  const fetchData = async () => {
    setIsLoading(true)
    try {
      let responseData
      if (variant === 'company') {
        const response = selectors.auth.user()
        responseData = response?.companies
      } else {
        const response = await wsApi.list()
        responseData = response.data
      }

      setData(responseData)
    } catch (error: unknown) {
      const errorMessage = (error as Error).message
      showNotification({
        message: tNotification(`lugarh.error.${errorMessage}`),
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormSubmit = () => {
    const selectedItem = data?.find(item => item.uuid === selected)
    const defaultWorkspace = {
      name: 'Geral',
      uuid: '664ba1156e77e39286817ee0'
    }
    if (selected && selectedItem) {
      window.localStorage.setItem(
        variant === 'workspace' ? 'Workspace-Tenant' : 'Company-Tenant',
        selectedItem.uuid
      )
      if (variant === 'company') {
        dispatch(
          authActions.setCurrentCompany({
            name: selectedItem.name,
            uuid: selected
          })
        )
        dispatch(authActions.setCurrentWorkspace(defaultWorkspace))
        window.localStorage.setItem('Workspace-Tenant', defaultWorkspace.uuid)
      } else {
        dispatch(
          authActions.setCurrentWorkspace({
            name: selectedItem.name,
            uuid: selected
          })
        )
      }
    }

    navigate(0)
  }

  const handleSelectChange = useCallback((selected: any) => {
    setSelected(selected)
  }, [])

  const convertData = useCallback(
    (data: any[]): any[] =>
      data.map(item => ({
        uuid: item.uuid,
        name: item.name,
        slug: item.slug || ''
      })),
    []
  )

  useEffect(() => {
    open && fetchData()
  }, [open, variant])

  useEffect(() => {
    if (!open) {
      return () => {
        setSelected(undefined)
      }
    }
  }, [open])

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={`ce-modal ${customClass}`}
      modalTitle={t(`lugarh.${variant}.title`)}
      modalSubtitle={t(`lugarh.${variant}.subtitle`)}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <DefaultModal.Content customClass='form'>
          <div className='form__step-2'>
            <div className='mui-modal__list ce-modal__list'>
              <SingleSelectableList<any>
                items={convertData(data || [])}
                onSelect={handleSelectChange}
                labels={['name', 'slug', 'uuid']}
                itemIdentifier='uuid'
                selected={selected || ''}
                variant={variant}
              />
            </div>
          </div>
          <div className='ce-modal__footer'>
            <PermissionAgent
              module='hiring-workflow'
              actions={['view']}
              onFail='hidden'
              Component={({ hidden }) =>
                hidden ? null : (
                  <Link
                    to={variant === 'workspace' ? '/workspaces' : '/companies'}
                    className='ce-modal__link theme-lugarh__link'
                  >
                    {t(`lugarh.${variant}.actions.manage`)}
                  </Link>
                )
              }
            />
            <ThemeButton
              title={t(`lugarh.${variant}.actions.change`)}
              template={ThemeButtonTemplate.PRIMARY_BLUE}
              customClass='mui-modal__button btn__primary form__button'
              onClick={handleFormSubmit}
            />
          </div>
        </DefaultModal.Content>
      )}
    </DefaultModal>
  )
}
